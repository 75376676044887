const percentageNl = [
  {
    choice: '0 % budget – 100 % duurzaamheid',
    value: 100,
  },
  {
    choice: '25 % budget – 75 % duurzaamheid',
    value: 75,
  },
  {
    choice: '50 % budget – 50 % duurzaamheid',
    value: 50,
  },
  {
    choice: '75 % budget – 25 % duurzaamheid',
    value: 25,
  },
  {
    choice: '100 % budget – 0 % duurzaamheid',
    value: 0,
  },
];

const percentageFr = [
  {
    choice: '0 % budget – 100 % durabilité',
    value: 100,
  },
  {
    choice: '25 % budget – 75 % durabilité',
    value: 75,
  },
  {
    choice: '50 % budget – 50 % durabilité',
    value: 50,
  },
  {
    choice: '75 % budget – 25 % durabilité',
    value: 25,
  },
  {
    choice: '100 % budget – 0 % durabilité',
    value: 0,
  },
];

export { percentageNl, percentageFr };
